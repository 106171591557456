import React from "react";
import { graphql } from "gatsby";

import notFoundImage from "../images/not-found-illustration.svg";

import Layout from "../components/Layout/Layout";
import Markdown from "../components/Markdown/Markdown";
import SEO from "../components/seo";
import LazyImg from "../components/LazyImg/LazyImg";

const NotFoundPage = ({ data }) => {
  const { meta_title, meta_description } = data.page.edges[0].node.data;
  const menu = data.allPrismicMenu.edges[0].node.data;
  const footer = data.allPrismicFooter.edges[0].node.data;

  const {
    not_found_page_title,
    not_found_page_content: { html: not_found_page_desc },
    cookie_message_button_text,
    cookie_message_text
  } = data.allPrismicGeneral.edges[0].node.data;

  return (
    <Layout
      lang="es"
      className="NotFound"
      menu={menu}
      footer={footer}
      cookie_message_text={cookie_message_text}
      cookie_message_button_text={cookie_message_button_text}
    >
      <SEO title={`Error | ${meta_title}`} description={meta_description} />

      <div className="NotFound-wrapper">
        <div className="container">
          <div className="container-md">
            <div className="NotFound-wrapper-in">
              <div>
                <LazyImg
                  className="NotFound-illustration"
                  src={notFoundImage}
                  alt={`EHUB! ${not_found_page_title}`}
                  spaceHolderFix={92.96}
                />
                <h1>{not_found_page_title}</h1>
                <Markdown input={not_found_page_desc} styleLink />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    page: allPrismicPage(
      filter: { uid: { eq: "home" }, lang: { eq: "es-es" } }
    ) {
      edges {
        node {
          data {
            meta_title
            meta_description
          }
        }
      }
    }

    allPrismicGeneral(filter: { lang: { eq: "es-es" } }) {
      edges {
        node {
          data {
            not_found_page_title
            not_found_page_content {
              html
            }
          }
        }
      }
    }

    allPrismicMenu(filter: { lang: { eq: "es-es" } }) {
      edges {
        node {
          data {
            menu_icon {
              url
              alt
            }
            body {
              ... on PrismicMenuBodyLink {
                primary {
                  link_text
                  link_url
                }
              }
              ... on PrismicMenuBodyButton {
                primary {
                  link_text
                  link_url
                  type
                }
              }
              __typename
            }
          }
        }
      }
    }

    allPrismicFooter(filter: { lang: { eq: "es-es" } }) {
      edges {
        node {
          data {
            logo {
              url
              alt
            }
            social_instagram_link
            social_facebook_link
            social_linkedin_link
            links {
              link_text
              link_url
            }
            bottom_links {
              link_text
              link_url
            }
            show_language_switch
          }
        }
      }
    }
  }
`;

export default NotFoundPage;
